import * as React from "react";
import RedCloud from "/static/red-tall-cloud.svg";
import Heading from "src/components/Heading";
import Button from "src/components/Button";
import Layout from "src/components/Layout";
import { PageContext } from "src/utils/context";

// markup
const NotFoundPage = () => {
  const location = { pathname: "/404/" };

  return (
    <PageContext.Provider
      value={{
        location: location,
      }}
    >
      <Layout>
        <main>
          <div className="mx-auto my-16 max-w-md text-center md:my-24 lg:my-20">
            <img
              className="mx-auto mb-12 block w-auto text-center lg:mb-16"
              src={RedCloud}
              alt="Cloud Gateway Red Cloud Logo"
            />
            <Heading
              className="text-darkblue font-display mb-12 block"
              size="h2"
            >
              Oops, something went wrong!
            </Heading>
            <p className="text-darkblue-700 mb-10 block tracking-[-0.03em]">
              The page you’re looking for couldn’t be found. It might have been
              removed, renamed or it didn’t exist in the first place.
            </p>
            <Button
              to={`/`}
              before="Back to homepage"
              className={`bg-brightgreen hover:bg-brightgreenhover mr-3 before:content-[attr(before)]`}
            >
              Back to homepage
            </Button>
          </div>
        </main>
      </Layout>
    </PageContext.Provider>
  );
};

export default NotFoundPage;
